import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import EngagementBlock from '../components/engagement/engagement-block'
import DefaultLayout from '../layouts/default'
import CarLoanImg from '../icons/car-loan.png'
import PersonalLoanImg from '../icons/deposit.png'
import BusinessLoanImg from '../icons/sign-contract.png'
import HomeLoanImg from '../icons/home-loan.png'
import ExpatLoanImg from '../icons/percentage-rate.png'
import RefinanceImg from '../icons/dynamic-rate.png'
import NonResidentLoanImg from '../icons/consumer-credit.png'
import LowDocLoanImg from '../icons/interest-rate.png'
import LineOfCreditImg from '../icons/credit-card.png'
import logo from '../images/logo.svg'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'

const ServiceBlock = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 600px;
`
const ServiceTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #1F3F68;
`

const ServiceDescription = styled.div`
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    width: 500px;
`

const ContentWrapper = styled.div`
  height: 300px;
  width: 1200px;
  margin: auto;
`

const Wrapper = styled.div`
  background: #F2EBE1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 34px;
  color: #1F3F68;
`
const Description = styled.p`
  color: #1F3F68;
  opacity: 0.6;
  width: 600px;
`

const Services = ({path}) => {
    return <DefaultLayout path={path}>
        <Header index={2} />
        <Helmet>
            <meta charSet="utf-8" />
            <title>Services - CY Finance</title>
        </Helmet>        
        <Wrapper className='px-20'>
            <ContentWrapper className='flex justify-between items-center'>
            <div className='flex flex-col gap-2'>
                <Title>Why clients choose us. </Title>
                <Title>And stay with us</Title>
                <Description>We deal with a wide variety of banks and non-banking lenders. By continually keeping up-to-date with the latest lending policies and products, our mission is to help you obtain the product that bet suites your circumstance and needs</Description>
            </div>
            <img src={logo} alt='logo' width={200} height={200} className='mr-60' />
            </ContentWrapper>
        </Wrapper>
        
        <div className='flex flex-col gap-20'>
            <div className='flex gap-10 justify-center py-10 px-40'>
                <ServiceBlock>
                    <img src={HomeLoanImg} alt="home-loan" className="h-40 w-40" />
                    <ServiceTitle>Home Loan - New Purchase</ServiceTitle>
                    <ServiceDescription>
                        A home loan is a type of loan from a bank or non-bank lender where real estate is used as collateral.  A new home loan typically has a maximum 30 year loan term and is repaid via regular repayment and accrues interest.
                    </ServiceDescription>
                </ServiceBlock>
                <ServiceBlock>
                    <img src={RefinanceImg} alt="refinance-img" className="h-40 w-40" />
                    <ServiceTitle>Home Loan - Refinance</ServiceTitle>
                    <ServiceDescription>
                        Similar to new home loan, refinancing a home loan means switching your existing home loan to a different lender or non-bank lender for securing a better deal, consolidating debts, or unlocking your equity of your current property, depending on the options you take.
                    </ServiceDescription>
                </ServiceBlock>
            </div>
        </div>
        <div className='flex flex-col gap-20'>
            <div className='flex gap-10 justify-center py-10 px-40'>
                <ServiceBlock>
                    <img src={CarLoanImg} alt='car-loan' className="h-40 w-40" />
                    <ServiceTitle>Car Loan</ServiceTitle>
                    <ServiceDescription>
                        Car loan is a secure loan for purchasing a new or used car. Car loan usually has loan term between one and seven years with fixed term repayment pending on the product and lender.
                    </ServiceDescription>
                </ServiceBlock>
                <ServiceBlock>
                    <img src={PersonalLoanImg} alt='personal-loan' className="h-40 w-40" />
                    <ServiceTitle>Personal Loan</ServiceTitle>
                    <ServiceDescription>
                        A personal loan is an insecure loan that let you borrow money for personal use, like a holiday, purchasing appliances or furniture and unexpected bills etc.
                    </ServiceDescription>
                </ServiceBlock>
            </div>
        </div>
        <div className='flex flex-col gap-20'>
            <div className='flex gap-10 justify-center py-10 px-40'>
                <ServiceBlock>
                    <img src={BusinessLoanImg} alt='business-loan' className=" h-40 w-40 object-cover" />
                    <ServiceTitle>Business Loan & Asset Finance</ServiceTitle>
                    <ServiceDescription>
                        A business loan is a loan specifically intended for business purposes. Business loan generally is used to support working capital and business related expenses.
                    </ServiceDescription>
                </ServiceBlock>
                <ServiceBlock>
                    <img src={ExpatLoanImg} className="h-40 w-40" />
                    <ServiceTitle>Expat Loan</ServiceTitle>
                    <ServiceDescription>
                    Expat loan is specifically designed for overseas Australian citizen or permanent resident who wants to obtain mortgage to purchase property in Australia.
                    </ServiceDescription>
                </ServiceBlock>
            </div>
        </div>
        <div className='flex flex-col gap-20'>
            <div className='flex gap-10 justify-center py-10 px-40'>
                <ServiceBlock>
                    <img src={NonResidentLoanImg} alt="non-resident-loan" className="h-40 w-40" />
                    <ServiceTitle>Non-Resident Loan</ServiceTitle>
                    <ServiceDescription>
                        Non-resident loan is specifically designed for non-resident who wants to obtain mortgage to purchase property in Australia.
                    </ServiceDescription>
                </ServiceBlock>
                <ServiceBlock>
                    <img src={LowDocLoanImg} alt="non-resident-loan" className="h-40 w-40" />
                    <ServiceTitle>Low Doc Loan</ServiceTitle>
                    <ServiceDescription>
                        Low Doc Loan is a type of loan that can be taken out by using less supporting documentation than what is required for a traditional full documentation loan.
                    </ServiceDescription>
                </ServiceBlock>
            </div>
        </div>
        <div className='flex flex-col gap-20'>
            <div className='flex gap-10 justify-center py-10 px-40'>
                <ServiceBlock>
                    <img src={LineOfCreditImg} alt="line-of-credit" className="h-40 w-40" />
                    <ServiceTitle>Line of Credit</ServiceTitle>
                    <ServiceDescription>
                        Different to car and personal loan which has a fixed term, a line of credit is a flexible loan that let you access extra money within your approved available limit whenever you want.
                    </ServiceDescription>
                </ServiceBlock>
                <ServiceBlock>
                </ServiceBlock>
            </div>
        </div>
        <EngagementBlock />
        <Footer index={2} />
    </DefaultLayout>
}

export default Services
